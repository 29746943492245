import Vue from 'vue'
import Vuex from 'vuex'
import producers from '@/assets/js/producers'
import artefacts from '@/assets/js/artefacts'


Vue.use(Vuex)

const state = {
    producers: producers,
    artefacts: artefacts,
    userArtefacts: [],
    clickMulti: 1,
    clickMultiPrice: 100,
    artefactProducerMulti: 1,
    cookies: 0
}

const mutations = {

    CLICK_COOKIE(state) {
        var click = 1 * state.clickMulti;
        state.cookies += click;
        localStorage.setItem('cookies', state.cookies)
    },
    CLICK_ARTEFACT(state, { artefact }) {
        if (artefact.type == "cookies") {
            state.cookies += artefact.cookies;
        } else if (artefact.type == "producer") {
            state.artefactProducerMulti += artefact.producerMulti
            setTimeout(
                () => (state.artefactProducerMulti -= artefact.producerMulti),
                artefact.time * 1000
            );
        }
    },
    ADD_COOKIES(state, { cookies }) {
        state.cookies += cookies
    },
    BUY_ARTEFACT(state, { artefactId }) {

        state.artefacts = state.artefacts.map(artefact => {
            if (artefact.id === artefactId) {
                ++artefact.purchased;
                state.cookies -= artefact.price;
                state.userArtefacts.push(artefact);
            }
            return artefact
        })

    },
    BUY_ARTEFACTUPGRADE(state, { artefactId }) {

        state.userArtefacts = state.userArtefacts.map(artefact => {

            if (artefact.id === artefactId) {
                ++artefact.purchased;
                state.cookies -= artefact.price;
                artefact.price = Math.round(2 * artefact.price)
                if (artefact.type == "cookies") {
                    artefact.cookies = Math.round(1.5 * artefact.cookies);
                } else {
                    artefact.time = Math.round(1.05 * artefact.time)
                    artefact.producerMulti = Math.round( artefact.producerMulti + 1);
                }

            }
            return artefact;
        });
    },
    BUY_PRODUCER(state, { producerId }) {
        state.producers = state.producers.map(producer => {
            if (producer.id === producerId) {
                producer.purchased++
                state.cookies -= producer.price
                producer.price = Math.round(1.22 * producer.price)
            }
            return producer
        })
    },
    BUY_CLICKUPGRADE(state) {
        state.cookies -= state.clickMultiPrice;
        state.clickMulti = (1.5 * state.clickMulti);
        state.clickMultiPrice = Math.round(1.55 * state.clickMultiPrice);
    },
    RETRIEVE_COOKIES(state) {
        if (localStorage.getItem('cookies') && !state.cookies) {
            //  state.cookies = localStorage.getItem('cookies');
        }
    }
}

const actions = {




}

const store = new Vuex.Store({
    state,
    mutations,
    actions
})

export default store;