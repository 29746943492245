<template>
  <v-container align="center" justify="center" fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <v-btn
          color="secondary"
          :disabled="!canBuy"
          @click="buy"
          max-width="200"
          class="mx-auto"
        >
          <v-row align="center" justify="center">
            <div class="text-h6">Klickkraft</div>
            <div ref="multi" class="multi">
              <v-icon right>mdi-currency-usd</v-icon>{{ clickMultiPrice }}
            </div>
          </v-row>
        </v-btn>
      </v-col>
      <artefact-section/>
      <v-col cols="12">
        <transition name="fade" mode="out-in">
          <v-sheet v-if="isProducerView">
            <v-btn
              class="ma-3"
              outlined
              text
              @click="isProducerView = !isProducerView"
            >
              <v-icon>mdi-storefront</v-icon></v-btn
            >
            <producer-store
              v-for="producer in producers"
              :producer="producer"
              :key="producer.name"
            />
          </v-sheet>
          <v-sheet v-else>
            <v-btn
              class="ma-3"
              outlined
              text
              @click="isProducerView = !isProducerView"
            >
              <v-icon>mdi-storefront</v-icon></v-btn
            >
            <artefact-store
              v-for="artefact in defaultArtefacts"
              :artefact="artefact"
              :key="artefact.name"
            />
          </v-sheet>
        </transition>
      </v-col> </v-row
  ></v-container>
</template>

<script>
import ArtefactStore from "@/components/ArtefactStore";

import * as helper from "@/assets/js/helper";
import animate from "@/assets/js/animate";
import ProducerStore from "./ProducerStore.vue";
import ArtefactSection from './ArtefactSection.vue';

export default {
  components: {
    ArtefactStore,
    ProducerStore,
    ArtefactSection,
  },
  data() {
    return {
      isProducerView: true,
    };
  },
  computed: {
    clickMultiPrice: function () {
      return helper.commarize(this.$store.state.clickMultiPrice);
    },
    defaultArtefacts: function () {
      return this.$store ? this.$store.state.artefacts : null;
    },
    clickMulti: function () {
      return helper.commarize(this.$store.state.clickMulti);
    },
    producers: function () {
      return this.$store ? this.$store.state.producers : null;
    },
    canBuy: function () {
      return this.$store.state.cookies >= this.$store.state.clickMultiPrice;
    },
  },
  methods: {
    buy() {
      if (this.canBuy) {
        animate(this.$refs.multi, "scale-up", 200);
        this.$store.commit("BUY_CLICKUPGRADE");
      }
    },
  },
};
</script>

<style scoped>
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.multi::after {
  animation: bounce 0.2s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
</style>