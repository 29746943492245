<template>
  <v-col cols="12">
    <v-card class="mt-n1 mx-auto" max-width="500">
      <v-card-title class="justify-center">Artefakte</v-card-title>
      <v-row align="center" justify="center">
        <v-slide-group v-if="hasArtefacts" show-arrows>
          <v-slide-item class="ma-1" v-for="artefact in userArtefacts" :key="artefact.name">
            <artefact :artefact="artefact" />
          </v-slide-item>
        </v-slide-group>
        <div v-else>
          <v-avatar class="ma-2" size="60"
            ><v-img src="/img/empty_artefact.png"></v-img>
          </v-avatar>
          <v-avatar class="ma-2" size="60"
            ><v-img src="/img/empty_artefact.png"></v-img>
          </v-avatar>
          <v-avatar class="ma-2" size="60"
            ><v-img src="/img/empty_artefact.png"></v-img>
          </v-avatar>
          <v-avatar class="ma-2" size="60"
            ><v-img src="/img/empty_artefact.png"></v-img>
          </v-avatar>
          <v-avatar class="ma-2" size="60"
            ><v-img src="/img/empty_artefact.png"></v-img>
          </v-avatar>
        </div>
      </v-row>
      <v-card-actions class="justify-center"> </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
import Artefact from "./Artefact.vue";
export default {
  components: {
    Artefact,
  },

  computed: {
    hasArtefacts: function () {
      return this.$store.state.userArtefacts.length > 0;
    },
    userArtefacts: function () {
      return this.$store ? this.$store.state.userArtefacts : null;
    },
  },
};
</script>