<template>
  <v-col cols="12">
    <v-card max-width="500" elevation="3" class="mx-auto">
      <v-row align="center" justify="center" no-gutters>
        <v-col md="3">
          <v-row justify="center">
            <v-avatar @click="overlay = true" size="80">
              <v-img
                :lazy-src="`${publicPath}` + artefact.image"
                :src="`${publicPath}` + artefact.image"
              />
            </v-avatar>
          </v-row>
        </v-col>
        <v-col md="4" @click="overlay = true">
          <v-row class="ma-1">
            <div class="font-weight-bold">{{ artefact.name }}</div>
          </v-row>
          <v-row class="ma-1">
            <div class="font-italic text-caption">
              <v-icon small>mdi-star-circle-outline</v-icon>
              {{ artefact.purchased }}
            </div>
          </v-row>
          <v-row class="ma-1">
            <div class="font-italic text-caption">
              <v-icon small>mdi-clock</v-icon>
              {{ artefact.cooldown }}s Abklingzeit
            </div>
          </v-row>
          <v-row class="ma-1">
            <div class="font-italic text-caption">
              <v-icon small>mdi-dumbbell</v-icon>
              {{ strength }}
            </div>
          </v-row>
        </v-col>
        <v-col md="">
          <transition name="">
            <v-btn
              ref="basket"
              class="basket"
              :disabled="!canBuy"
              @click="buy"
              color="secondary"
              depressed
            >
              <div>{{ price }}</div>
              <v-icon right v-if="!hasItem" color="white">mdi-basket </v-icon>
              <v-icon right v-else color="white">mdi-chevron-triple-up</v-icon>
            </v-btn>
          </transition>
        </v-col>
      </v-row>
      <v-overlay :opacity="0.8" :absolute="absolute" :value="overlay">
        <v-row align="center" justify="center">
          <v-col md="4">
            {{ artefact.description }}
          </v-col>
          <v-col md="1" align="center" justify="center">
            <v-btn @click="overlay = false">X</v-btn>
          </v-col>
        </v-row>
      </v-overlay>
    </v-card>
  </v-col>
</template>

<script>
import * as helper from "@/assets/js/helper";

export default {
  props: ["artefact"],
  data() {
    return {
      absolute: true,
      overlay: false,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    totalCookies: function () {
      return this.$store.state.cookies;
    },
    price: function () {
      return helper.commarize(this.artefact.price);
    },
    canBuy: function () {
      return this.totalCookies >= this.artefact.price;
    },
    strength: function () {
      if (this.artefact.type == "cookies") {
        return helper.commarize(this.artefact.cookies) + "  S1/click";
      } else if (this.artefact.type == "producer") {
        return (
          this.artefact.time +
          "s " +
          this.artefact.producerMulti +
          "x Producers "
        );
      } else {
        return null;
      }
    },
    hasItem: function () {
      return this.artefact.purchased >= 1;
    },
  },
  methods: {
    buy() {
      if (this.canBuy) {
        if (this.hasItem) {
          this.$store.commit("BUY_ARTEFACTUPGRADE", {
            artefactId: this.artefact.id,
          });
        } else {
          this.$store.commit("BUY_ARTEFACT", { artefactId: this.artefact.id });
        }
      }
    },
  },
};
</script>

<style scoped>
.basket:active {
  animation: bounce 0.2s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}
</style>