<template>
  <v-col cols="12">
    <v-card
      max-width="400"
      :disabled="!canBuy"
      elevation="2"
      shaped
      class="mx-auto"
    >
      <v-row>
        <v-card-title>
          <v-icon large left> {{ producer.icon }}</v-icon>
          {{ producer.name }} <v-icon right>mdi-currency-usd</v-icon>{{ price }}
        </v-card-title>
      </v-row>
      <div class="d-flex flex-no-wrap justify-space-between">
        <v-col md="4" class="ma-2">
          <v-row align="center">
            <v-icon left small>mdi-star-circle-outline</v-icon> 
            {{ producer.purchased }}</v-row
          >
          <v-row align="center">
            <v-icon left small>mdi-dumbbell</v-icon>
            {{ cookiesPerSecond }}
            S1/s
          </v-row>
        </v-col>
        <v-card-actions>
          <transition name="basket">
            <v-btn
              ref="basket"
              class="basket"
              :disabled="!canBuy"
              @click="buy"
              color="secondary"
              depressed
            >
              <v-icon v-if="!hasItem" color="white">mdi-basket </v-icon>
              <v-icon v-else color="white">mdi-chevron-triple-up</v-icon>
            </v-btn>
          </transition>
        </v-card-actions>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import * as helper from "@/assets/js/helper";

export default {
  props: ["producer"],
  computed: {
    totalCookies: function () {
      return this.$store.state.cookies;
    },
    cookiesPerSecond: function () {
      return helper.commarize(this.producer.cookiePerSeconds);
    },
    canBuy: function () {
      return this.totalCookies >= this.producer.price;
    },
    price: function () {
      return helper.commarize(this.producer.price);
    },
    hasItem: function () {
      return this.producer.purchased >= 1;
    },
  },
  methods: {
    buy() {
      if (this.canBuy) {
        this.$store.commit("BUY_PRODUCER", { producerId: this.producer.id });
      }
    },
  },
};
</script>

<style scoped>
.basket:active {
  animation: bounce 0.2s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}
</style>