<template>
  <div>
    <v-row class="text-center">
      <v-col>
        <div class="ma-3 text-lighten">
          Klickmulti: {{ clickMulti }} / click
        </div>
        <div class="ma-3 text-lighten">
          Producers: {{ totalCookieCount }} S1/s
        </div>
        <div class="ma-3 text-h4">TOTAL</div>
        <transition name="bounce">
          <div class="ma-3 text-h2">
            {{ cookies }}
          </div></transition
        >
      </v-col>
    </v-row>
    <clicker />
    <StoreSection />

    <v-bottom-navigation dense color="red" grow>
      <v-btn>
        <span>Made with Love</span>

        <v-icon>mdi-heart</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import Clicker from "@/components/Clicker.vue";
import store from "../vuex/store.js";
import * as helper from "@/assets/js/helper";
import StoreSection from "@/components/StoreSection";

export default {
  store,
  components: {
    Clicker,
    StoreSection,
  },
  data() {
    return {
      delayMs: 1000,
      drawer: null,
      totalCookieCount: 0,
      items: [
        {
          title: "Wohniküche",
          icon: "mdi-rv-truck",
          subtitle: "5 s1 per second",
        },
        {
          title: "Vorschlaghammer",
          icon: "mdi-hammer",
          subtitle: "30 s1 per second",
        },
      ],
    };
  },
  computed: {
    clickMulti: function () {
      return helper.commarize(this.$store.state.clickMulti);
    },
    producers: function () {
      return this.$store.state.producers;
    },
    cookies: function () {
      if (this.$store.state.cookies >= 1000) {
        return this.commarize(this.$store.state.cookies);
      } else {
        return this.$store.state.cookies;
      }
    },
  },
  mounted() {
    this.farmProducers();
    this.retrievCokies();
  },
  methods: {
    commarize(count) {
      return helper.commarize(count);
    },
    retrievCokies() {
      store.commit("RETRIEVE_COOKIES");
    },

    farmProducers() {
      setInterval(() => {
        let cookieCount = 0;

        for (let producer of this.producers) {
          cookieCount +=
            producer.cookiePerSeconds *
            producer.purchased *
            this.$store.state.artefactProducerMulti;
        }
        this.$store.commit("ADD_COOKIES", { cookies: cookieCount });
        helper.commarize((this.totalCookieCount = cookieCount));
      }, this.delayMs);
    },
  },
};
</script>
