
export function popUpCount(color, animation, e) {
    var img = new Image(30, 30);
    var duration;
    if (color == "green") {
        img.src = require('../leaf.png')
        img.width = 150;
        img.height = 150;
        duration = 3000;
    }
    if (color == "white") {
        img.src = require('../s1Black.png')
        duration = 500;

    } else if (color == "black") {
        img.src = require('../s1White.png')
        duration = 500;
    }
    Object.assign(img.style, {
        position: 'absolute',
        top: `${e.clientY}px`,
        left: `${e.clientX}px`,
        userSelect: 'none',
        pointerEvents: 'none',
    })

    document.body.appendChild(img)
    img.classList.add('animated', animation)
    setTimeout(() => {
        document.body.removeChild(img)
    }, duration)

}


export function startArtefactRain() {
    var img = new Image(30, 30);
    img.src = "../s1Black.png"
}

