import uuid from 'uuid/v4'

const producers = [
    {
        id: uuid(),
        name: 'Cursor',
        price: 30,
        cookiePerSeconds: 2,
        purchased: 0,
        icon: "mdi-cursor-default"
    },
    {
        id: uuid(),
        name: 'Blaue Farbdose',
        price: 1500,
        cookiePerSeconds: 150,
        purchased: 0,
        icon: "mdi-glass-stange"
    },
    {
        id: uuid(),
        name: 'Vorschlaghammer',
        price: 20000,
        cookiePerSeconds: 420,
        purchased: 0,
        icon: "mdi-hammer"
    },
    {
        id: uuid(),
        name: 'Katzenklo',
        price: 100000,
        cookiePerSeconds: 1000,
        purchased: 0,
        icon: 'mdi-timer-sand'
    },
    {
        id: uuid(),
        name: '2,5 Radler',
        price: 250000,
        cookiePerSeconds: 4200,
        purchased: 0,
        icon: "mdi-glass-mug-variant"
    },
    {
        id: uuid(),
        name: 'Wohniküche',
        price: 500000,
        cookiePerSeconds: 10000,
        purchased: 0,
        icon: "mdi-rv-truck"
    },
    {
        id: uuid(),
        name: 'Pizza Hawaii',
        price: 2500000,
        cookiePerSeconds: 42000,
        purchased: 0,
        icon: "mdi-pizza"
    },

    {
        id: uuid(),
        name: 'Bierpongtisch',
        price: 10000000,
        cookiePerSeconds: 100000,
        purchased: 0,
        icon: "mdi-billiards-rack"
    },
    {
        id: uuid(),
        name: "Chantal",
        price: 500000000,
        cookiePerSeconds: 420000,
        purchased: 0,
        icon: "mdi-face-woman-shimmer-outline"
    },
    {
        id: uuid(),
        name: 'Atombombe',
        price: 2500000000,
        cookiePerSeconds: 10000000,
        purchased: 0,
        icon: "mdi-nuke"
    },
]

export default producers