import uuid from 'uuid/v4'


const artefacts = [
    {
        id: uuid(),
        name: '5,0 Original',
        description: 'Genieße ein kühles 5.0',
        type: "cookies",
        price: 2000,
        cooldown: 5,
        purchased: 0,
        cookies: 1000,
        image: "img/50.png"
    },
    {
        id: uuid(),
        name: 'Jägermeister',
        description: 'Multipliziert den Producer-Multiplikator für 10 Sekunden',
        price: 10000,
        cooldown: 20,
        time: 45,
        type: "producer",
        purchased: 0,
        producerMulti: 2,
        image: "img/jäger.png"
    },
    {
        id: uuid(),
        name: 'Jagtfürst',
        description: 'Multipliziert den Producer-Multiplikator für 20 Sekunden',
        price: 50000,
        type: "producer",
        producerMulti: 4,
        cooldown: 40,
        time: 45,
        purchased: 0,
        image: "img/jagtfürst.png"
    }, {
        id:
            uuid(),
        name: 'Lukas',
        type: "cookies",
        description: 'Lukas braucht keine Beschreibung',
        price: 500000,
        cooldown: 15,
        purchased: 0,
        cookies: 100000,
        image: "img/lukas.png"
    },

    {
        id: uuid(),
        name: 'Mellow',
        description: ':(',
        price: 1000000,
        type: "cookies",
        cooldown: 25,
        purchased: 0,
        cookies: 500000,
        image: "img/mellow.png"
    }]

export default artefacts;