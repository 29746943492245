<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <transition name="cookie">
          <img
            ref="cookie"
            class="cookie"
            :src="imgSrc"
            @click="clickCookie"
            loading="lazy"
            alt=""
            height="200"
          />
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "../vuex/store";
import { popUpCount } from "@/assets/js/ui";

export default {
  methods: {
    clickCookie(e) {
      this.showPopup(e);
      store.commit("CLICK_COOKIE");
    },
    showPopup(e) {
      var color;

      if (this.cookies == 419 || this.cookies == 4200 ) {
        color = "green";
      } else if (!this.$vuetify.theme.dark) {
        color = "white";
      } else {
        color = "black";
      }
      popUpCount(color, "fadeOutUp", e);
    },
  },
  computed: {
    imgSrc: function () {
      var image;
      if (!this.$vuetify.theme.dark) {
        image = require("../assets/s1Black.png");
      } else {
        image = require("../assets/s1White.png");
      }
      return image;
    },

    cookies: function () {
      return store.state.cookies;
    },
  },
};
</script>

<style scoped>
.cookie:active {
  animation: bounce 0.2s;
}

.popUp:active {
  animation: bounce 3s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes GoUp {
  0% {
    opacity: 1;
  }
  100% {
    top: 0px;
    opacity: 0;
  }
}
</style>

