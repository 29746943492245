<template>
  <div>
    <v-badge dot overlap :color="batchColor">
      <v-btn fab icon :disabled="deactivated"  @click="clickArtefact(artefact)">
        <v-avatar
          size="55"
          ref="artefact"
          class="artefact ma-1"
        >
          <v-img :src="'/' + this.artefact.image" alt="" />
          <v-overlay :absolute="absolute" :value="deactivated" :opacity="opacity">
          </v-overlay>
        </v-avatar>
      </v-btn>
    </v-badge>
  </div>
</template>


<script>
export default {
  props: ["artefact"],
  data() {
    return {
      deactivated: false,
      absolute: true,
      opacity: 0.2,
    };
  },

  computed: {
    batchColor: function () {
      return this.deactivated ? "red lighten-2" : "green lighten-2";
    },
  },
  methods: {
    clickArtefact() {
      this.$store.commit("CLICK_ARTEFACT", { artefact: this.artefact });
      this.deactivateForSeconds();
    },
    deactivateForSeconds() {
      this.deactivated = true;
      setTimeout(
        () => ((this.deactivated = false), (this.overlay = !this.overlay)),
        this.artefact.cooldown * 1000
      );
    },
  },
};
</script>

<style scoped>
.artefact:active {
  animation: bounce 0.1s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>